import { useLocation } from 'react-router-dom';
import useIsGroupReservation from 'hooks/useIsGroupReservation';

const useCalculateHostPayout = () => {
  const { state } = useLocation();
  const { isGroupReservation, isGroupReservationEnabled } = useIsGroupReservation();
  if (isGroupReservationEnabled && isGroupReservation) {
    const quotes = state?.quoteData?.quote;
    return quotes?.reduce((acc, quote) => {
      const ratePlan = quote?.rates?.ratePlans?.find((rp) => {
        return rp?.ratePlan?._id === state?.ratePlan?._id;
      })?.ratePlan;
      return acc + ratePlan?.money?.hostPayout;
    }, 0);
  }

  return state?.selectedRatePlanData?.ratePlan?.money?.hostPayout || state?.ratePlan?.money?.hostPayout;
};

export default useCalculateHostPayout;