export const BANK_TRANSFER = 'DISCO-engines-bank-transfer';
export const BED_ARRANGEMENT = 'DISCO-bed-arrangement';
export const HOUSE_RULES = 'DISCO-house-rules';
export const CREDIT_CARD_USAGE = 'CreditCardReusage';
export const GROUP_RESERVATIONS = 'DISCO-BE-GROUP-RESERVATIONS';
export const NEW_TRANSLATIONS_UI = 'DISCO-engines-translations';
export const SHOW_NO_PROPERTIES_PAGE = 'DISCO-BEP-show-no-properties-page';
export const PRICE_FILTER_FIX = 'DISCO-BE-nightly-rates-filter';
export const FORCE_THUMBNAIL_WORKAROUND = 'DISCO-BE-thumbnail-workaround';
export const ZERO_AMOUNT_NOTIFICATION = 'CMGZeroAmountNotification';
export const BATH_SPACE_ENABLED = 'PRO-bath-space-enabled';
export const BOOKING_ENGINE_NEW_SETUP = 'DISCO-online-booking-solutions-be-new-setup';
export const PRIVACY_POLICY_TERMS = 'DISCO-privacy-policy-terms';
export const NEW_CANCELLATION_POLICY_UI = 'DISCO-BE-cancellation-policy';
export const FORTER_FRAUD_VALIDATION = 'ForterFraudValidation';
export const FORTER_3DS = 'GOLD_forter_3ds';
